import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"

// Styles
import s from "./Pagination.module.sass"
// import ArrowLeft from "../../icons/blog/arrow-left.svg"
import ArrowRight from "../../icons/blog/arrow-right.svg"
import { Container, Row } from "../index"

const Pagination = ({ className, parentPath, nextPage, previousPage, countOfPages, currentPage }) => {

  console.log(parentPath);
  
  const pages = []
  if (countOfPages !== 1) {
    if(countOfPages <= 3) {
      for(let pageNumber = 1; pageNumber <= countOfPages; pageNumber++) {
        if(pageNumber === currentPage) {
          pages.push(
            <span className={classNames(s.page, s.currentPage)}>{pageNumber}</span>
          )
        } else {
          pages.push(
            pageNumber !== 1 ? <Link className={s.page} to={`${parentPath}/${pageNumber}`}>{pageNumber}</Link>
            : <Link className={s.page} to={`${parentPath}`}>{pageNumber}</Link>
          )
        }
      }
    } else {
      if (currentPage !== 1) {
        pages.push(
          <Link className={s.page} to={parentPath}>1</Link>
        )
      }
      if (currentPage > 3) {
        pages.push(
          <span className={classNames(s.page, s.dots)}>...</span>
        )
      }
      if (currentPage >= 3) {
        pages.push(
          <Link className={s.page} to={`${parentPath}/${currentPage - 1}`}>{currentPage - 1}</Link>
        )
      }
      pages.push(
        <span className={classNames(s.page, s.currentPage)}>{currentPage}</span>
      )
      if (countOfPages - currentPage > 1) {
        pages.push(
          <Link className={s.page} to={`${parentPath}/${currentPage + 1}`}>{currentPage + 1}</Link>
        )
      }
      if (countOfPages - currentPage > 2) {
        pages.push(
          <span className={classNames(s.page, s.dots)}>...</span>
        )
      }
      if (currentPage !== countOfPages) {
        pages.push(
          <Link className={s.page} to={`${parentPath}/${countOfPages}`}>{countOfPages}</Link>
        )
      }
    }
  }

  return (
    <div className={classNames(className, s.wrapper)}>
      <Container>
        <Row justify="justify-content-between" align='align-items-center'>
          <div className={s.arrow}>
            {previousPage && (
              <Link to={previousPage} className={s.arrowLeft}><ArrowRight/></Link>
            )}
          </div>
          <div className={s.pages}>
            {pages}
          </div>
          <div className={s.arrow}>
            {nextPage && (
              <Link to={nextPage}><ArrowRight/></Link>
            )}
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default Pagination
