import React, { useContext } from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import classNames from "classnames";
// ui-kit
import {
    Container,
    Title,
} from '../../../ui-kit'
// styles
import s from './FirstScreen.module.sass'
// context
import {LayoutContext} from '../../Layouts/layout'

export default function FirstScreen({ articles, Pagination }) {

    const {local: {YAMLGlobal}} = useContext(LayoutContext);
    
    return(
        <section className={s.firstScreen}>
            <Container>
                <Title type="subtitle" className={classNames("center", s.subtitle)}>utemsolar</Title>
                <Title>{YAMLGlobal.menu.blog}</Title>
                <div className={s.wrapper}>
                    {
                        articles.reduce(
                            function(accumulator, currentValue, currentIndex, array) {
                            if (currentIndex % 2 === 0)
                                accumulator.push(array.slice(currentIndex, currentIndex + 2));
                            return accumulator;
                        }, []).map((article, index) => {
                            return (
                                <div className={s.articles} key={index}>
                                    {article[0] && <Blog key={article[0].node.id} id={article[0].node.id} {...article[0]}/>}
                                    {article[1] && <Blog key={article[1].node.id} id={article[1].node.id} {...article[1]}/>}
                                </div>
                            )
                        })
                    }
                </div>
                <Pagination/>
            </Container>
        </section>
    )
}

const Blog = ({node}) => {
    const {local: {langBase, YAMLGlobal}} = useContext(LayoutContext);
    let img = node.image.localFile ? node.image.localFile?.childImageSharp.fluid : '';
    
    return (
        <div className={s.article}>
            <Link to={`${langBase}/blog/${node.slug}`} className={s.link}>
                <div className={s.inner}>
                    <div className={s.img}><Img className={s.gatsby} fluid={img}/></div>
                    <div className={s.content}>
                        <h4 className={s.title}>{node.title || node.title_ua}</h4>
                        <div className={s.info}>
                            <p className={s.description}>{node.description || node.description_ua}</p>
                            <div className={s.footer}>
                                <p className={s.date}>{node.created_on}</p>
                                <span className={s.more}>{YAMLGlobal.button.more}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )
}

Blog.propTypes = {
    img: PropTypes.object,
    title: PropTypes.string,
    description: PropTypes.string,
    date: PropTypes.string,
}